<template>
  <form
    class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center"
    @submit.prevent="handleSubmit"
  >
    <fieldset class="tw-mb-3 lg:tw-mb-0 lg:tw-mr-4 tw-text-center lg:tw-text-left">
      <input
        v-for="n in 4"
        :key="n"
        ref="inputs"
        :class="inputClass"
        inputmode="numeric"
        maxlength="1"
        pattern="[0-9]*"
        type="text"
        @keyup="handleKeyup"
        @keydown.arrow-left="handleArrowLeft"
        @keydown.arrow-right="handleArrowRight"
        @keydown.backspace="handleBackspace"
        @paste="handlePaste"
      >
    </fieldset>
    <Button
      :type="buttonType"
      :disabled="!isPasscodeValid"
      @click="handleButtonClick"
    >
      Verify Code
    </Button>
  </form>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { addQueryParamsToPath, parseOpenMountainApiError } from '@@/utils/CommonUtils';
import { getPromoCodeBannerType, navigateReturnTo } from '@@/utils/LoginUtils';
import { useLoginStore } from '@@/stores/Login';
import { useUserStore } from '@@/stores/User';
import LoginViewMixin from '@@/components/Login/LoginViewMixin';

export default {
  name: 'EnterPasscodeForm',

  mixins: [
    LoginViewMixin,
  ],

  data() {
    return {
      isSubmitting: false,
      passcode: [null, null, null, null],
      return_to: null,
    };
  },

  computed: {
    ...mapState(useLoginStore, {
      email: (state) => state.credentials.email,
    }),

    buttonType() {
      return ButtonTypes.primary;
    },

    /**
     * The passcode is valid when four valid characters have been entered.
     */
    isPasscodeValid() {
      return this.passcode.every((char) => this.isCharacterValid(char));
    },

    inputClass() {
      return [
        'tw-inline-block',
        'tw-w-9 tw-h-16',
        'tw-mr-2 tw-px-1 tw-py-1.5',
        'tw-border tw-rounded-md',
        'tw-text-2xl tw-font-semibold tw-text-center',
        'focus-within:tw-shadow-lg tw-outline-none',
        this.$style.input,
      ].join(' ');
    },
  },

  mounted() {
    if (this.$route.query.return_to) {
      this.return_to = this.$route.query.return_to;
    }
    else {
      this.return_to = '/user/favorites';
    }
  },

  methods: {
    ...mapActions(useLoginStore, ['makeLoginPasscodeRequest', 'makeRegisterPasscodeRequest']),
    ...mapActions(useUserStore, ['setBanner']),

    findInputIndex(event) {
      return this.$refs.inputs.findIndex((input) => input === event?.target);
    },

    focusInput(index) {
      if (!this.isInputIndexValid(index)) {
        return;
      }

      this.$refs.inputs[index].focus();

      // Move cursor to end of value when present
      // @see https://stackoverflow.com/questions/511088/use-javascript-to-place-cursor-at-end-of-text-in-text-input-element
      window.setTimeout(() => {
        const input = this.$refs?.inputs?.[index];

        if (!input) {
          return;
        }

        input.selectionStart = 10000;
        input.selectionEnd = 10000;
      });
    },

    /**
     * Focus on previous input when left arrow key pressed
     */
    handleArrowLeft(event) {
      const index = this.findInputIndex(event);
      this.focusInput(index - 1);
    },

    /**
     * Focus on next input when right arrow key pressed
     */
    handleArrowRight(event) {
      const index = this.findInputIndex(event);
      this.focusInput(index + 1);
    },

    handleBackspace(event) {
      const index = this.findInputIndex(event);

      if (!this.isInputIndexValid(index)) {
        return;
      }

      this.passcode[index] = '';

      const { value } = this.$refs.inputs[index];

      // If the input does not have a value then focus on previous input.
      if (!value && index > 0) {
        this.focusInput(index - 1);
        event.preventDefault();
      }
    },

    handleButtonClick(event) {
      event?.originalEvent?.preventDefault?.();
      this.handleSubmit();
    },

    /**
     * When a valid alphanumeric character was pressed then save the value and either focus on the
     * next input or submit the form if the passcode is valid.
     */
    handleKeyup(event) {
      const { ctrlKey, metaKey } = event;

      // Use the input value instead of the event key because the event key is not populated
      // correctly in Chrome on Android or Safari on iOS.
      // SEE: https://stackoverflow.com/questions/36753548/keycode-on-android-is-always-229

      const key = event.target.value;

      if (key && key.length === 1 && this.isCharacterValid(key) && !ctrlKey && !metaKey) {
        const index = this.findInputIndex(event);

        if (!this.isInputIndexValid(index)) {
          return;
        }

        const { value } = this.$refs.inputs[index];

        // Only focus on the next input if the input value is different from the previous value.
        // This allows the user to press the backspace key to navigate back to the previous input
        // without the keyup event being triggered a second time and navigating forward to the
        // input that just lost focus!

        const shouldFocus = this.passcode[index] !== value;

        this.passcode[index] = value;

        if (shouldFocus) {
          this.focusInput(index + 1);
        }

        if (this.isPasscodeValid) {
          this.handleSubmit();
        }
      }
    },

    handlePaste(event) {
      event.preventDefault();

      const index = this.findInputIndex(event);
      const passcode = event.clipboardData.getData('text');
      this.populateInputs(passcode, index);

      if (this.isPasscodeValid) {
        this.handleSubmit();
      }
    },

    async handleSubmit() {
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;

      /* eslint camelcase: off */
      if (!this.isPasscodeValid) {
        this.$toast.open({ message: 'Please enter a valid 4-digit passcode', type: 'error' });
        this.isSubmitting = false;
        return;
      }

      const handleError = (message) => this.$toast.open({ message, type: 'error' });

      this.$loading.start();

      try {
        const passcode = this.passcode.join('');
        let response;

        if (this.isLoginLinkSentView) {
          response = await this.makeLoginPasscodeRequest({ passcode });
        }
        else {
          const trial_requested = this.isRegisterLinkSentView;
          response = await this.makeRegisterPasscodeRequest({ passcode, trial_requested });
        }

        const { messages, promo_code, user } = response;

        if (user) {
          if (messages) {
            const type = getPromoCodeBannerType(promo_code);
            const banner = {
              message: messages.join(' '),
              type,
            };
            this.setBanner({ banner, saveToSessionStorage: true });
          }

          if (this.isRegisterLinkSentView) {
            this.return_to = addQueryParamsToPath(this.return_to, { source: 'reg_complete' });

            const { gtag } = useGtag();

            if (gtag) {
              gtag('event', 'Web_Trial_Confirmed');
            }
          }

          navigateReturnTo(this.$router, this.return_to);
        }
        else {
          handleError('Unable to login with passcode.');
        }
      }
      catch (e) {
        const { message } = parseOpenMountainApiError(e);
        handleError(message || 'Unable to login with passcode');
      }

      this.$loading.finish();
      this.isSubmitting = false;
    },

    isCharacterValid(char) {
      return /[0-9]/.test(char);
    },

    /**
     * Be one with Sentry. Prevent the error from happening.
     */
    isInputIndexValid(index) {
      return !!this.$refs?.inputs?.[index];
    },

    populateInputs(passcode, startIndex) {
      // Filter out invalid characters
      const digits = [...passcode].filter((char) => this.isCharacterValid(char));

      // Assign each digit of the passcode to the corresponding input
      digits.forEach((digit, index) => {
        const inputIndex = startIndex + index;

        if (this.isInputIndexValid(inputIndex)) {
          this.$refs.inputs[inputIndex].value = digit;
          this.passcode[inputIndex] = digit;
        }
      });

      // Focus on input at the end of the pasted value
      const lastInputIndex = Math.min(4, startIndex + digits.length) - 1;
      this.focusInput(lastInputIndex);
    },
  },
};
</script>

<style module>
.input {
  background-color: var(--input-background-color);
  border-color: var(--input-border-color);
  color: var(--text-darkest);
}

.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
  appearance: none;
}

.input:focus,
.input:focus-within {
  border-color: var(--input-border-color-focus);
}
</style>
