<template>
  <Card
    :class="{ 'tw-mb-4': passcodeValidate }"
    :has-body-padding="true"
  >
    <template
      #body
    >
      <div class="tw-flex tw-flex-col lg:tw-flex-row tw-items-center lg:tw-mx-8 lg:tw-my-6">
        <font-awesome-icon
          :class="iconClass"
          :icon="['fad', 'paper-plane']"
        />
        <div v-if="isLoginLinkSentView && passcodeValidate">
          <p class="tw-mb-3 lg:tw-mb-4">
            To log in to {{ $config.siteName }}, click the button in the email we sent to
            {{ emailSentTo }}.
          </p>
          <p class="tw-mb-3 lg:tw-mb-4">
            <strong>Or</strong> enter the code from the email below.
          </p>
          <EnterPasscodeForm />
        </div>
        <p
          v-else-if="isLoginLinkSentView"
          class="tw-inline-block"
        >
          To log in to {{ $config.siteName }}, click the button in the email we sent to
          {{ emailSentTo }}. Didn’t receive it? Check your spam folder or click the button below
          to resend.
        </p>
        <p
          v-else-if="isForgotPasswordLinkSentView"
          class="tw-inline-block"
        >
          To reset your password, click the button in the email we sent to {{ emailSentTo }}.
          Didn’t receive it? Check your spam folder or click the button below to resend.
        </p>
        <div
          v-else-if="isRegisterLinkSentView && passcodeValidate"
          class="tw-inline-block"
        >
          <p class="tw-mb-3 lg:tw-mb-4">
            To confirm your email, click the button in the email we sent to {{ emailSentTo }}.
          </p>
          <p class="tw-mb-3 lg:tw-mb-4">
            <strong>Or,</strong> enter the code from the email below.
          </p>
          <EnterPasscodeForm />
        </div>
        <p
          v-else-if="isRegisterLinkSentView"
          class="tw-inline-block"
        >
          To confirm your email, click the button in the email we sent to {{ emailSentTo }}. Didn’t
          receive it? Check your spam folder or click the button below to resend.
        </p>
        <p
          v-else-if="isRegisterLinkVerifiedView"
          class="tw-inline-block"
        >
          It looks like you may have registered in another tab or device. You can close this tab.
        </p>
        <p
          v-else-if="isChangeEmailLinkSentView"
          class="tw-inline-block"
        >
          To confirm your new email address, click the button in the email we sent to
          {{ emailSentTo }}. Didn’t receive it? Check your spam folder or click the button below to
          resend.
        </p>
        <p
          v-else-if="isDeleteAccountLinkSentView"
          class="tw-inline-block"
        >
          To delete your account, click the button in the email we sent to
          {{ (user && user.email) || 'you' }}. Didn't receive it? Check your spam folder or click
          the button below to resend.
        </p>
      </div>
    </template>
  </Card>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { loginViews, useLoginStore } from '@@/stores/Login';
import { useUserStore } from '@@/stores/User';
import LoginViewMixin from '@@/components/Login/LoginViewMixin';

export default {
  name: 'LinkSentView',

  mixins: [
    LoginViewMixin,
  ],

  data() {
    return {
      registerCheckAttempts: 0,
      registerCheckIntervalId: null,
    };
  },

  computed: {
    ...mapState(useLoginStore, {
      email: (state) => state.credentials.email,
      loginView: (state) => state.loginView,
      passcodeValidate: (state) => state.credentials.passcode_validate,
    }),

    ...mapState(useUserStore, {
      user: (state) => state.user,
    }),

    iconClass() {
      return [
        'tw-block',
        'tw-w-14 lg:tw-w-20',
        'tw-h-14 lg:tw-h-20',
        'tw-mb-4 lg:tw-mb-0',
        'lg:tw-mr-8',
        this.$style.icon,
      ];
    },

    emailSentTo() {
      return this.email || 'you';
    },
  },

  mounted() {
    if (this.isRegisterLinkSentView) {
      this.startRegisterCheckPoll();
    }
  },

  unmounted() {
    this.stopRegisterCheckPoll();
  },

  methods: {
    ...mapActions(useLoginStore, ['makeRegisterCheckRequest', 'setLoginView']),
    ...mapActions(useUserStore, ['fetchUser']),

    /**
     * Make a request to GET /user/register/check every 5 seconds to check whether the user
     * verified their email address in another tab or device. If the user verifies their email in
     * another tab or device then stop polling and display the registerLinkVerified view. Also try
     * to fetch the user so that if they verified in another tab in the same browser, the UI can be
     * updated to show the authenticated view.
     */
    async pollRegisterCheck() {
      this.registerCheckAttempts += 1;

      if (this.registerCheckAttempts > 120) {
        this.stopRegisterCheckPoll();
        return;
      }

      try {
        const { verified } = await this.makeRegisterCheckRequest();

        if (verified) {
          this.stopRegisterCheckPoll();
          this.setLoginView(loginViews.registerLinkVerified);
          await this.$nextTick();
          this.fetchUser();
        }
      }
      catch (e) {
        this.stopRegisterCheckPoll();
      }
    },

    startRegisterCheckPoll() {
      this.registerCheckIntervalId = window.setInterval(() => this.pollRegisterCheck(), 5000);
    },

    stopRegisterCheckPoll() {
      window.clearInterval(this.registerCheckIntervalId);
    },
  },
};
</script>

<style module>
.icon {
  color: var(--light-blue);
}
</style>
